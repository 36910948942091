exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/blog/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-conditions-we-treat-ankle-and-foot-pain-js": () => import("./../../../src/pages/conditions-we-treat/ankle-and-foot-pain.js" /* webpackChunkName: "component---src-pages-conditions-we-treat-ankle-and-foot-pain-js" */),
  "component---src-pages-conditions-we-treat-back-pain-js": () => import("./../../../src/pages/conditions-we-treat/back-pain.js" /* webpackChunkName: "component---src-pages-conditions-we-treat-back-pain-js" */),
  "component---src-pages-conditions-we-treat-discomfort-in-advancing-years-js": () => import("./../../../src/pages/conditions-we-treat/discomfort-in-advancing-years.js" /* webpackChunkName: "component---src-pages-conditions-we-treat-discomfort-in-advancing-years-js" */),
  "component---src-pages-conditions-we-treat-js": () => import("./../../../src/pages/conditions-we-treat.js" /* webpackChunkName: "component---src-pages-conditions-we-treat-js" */),
  "component---src-pages-conditions-we-treat-knee-pain-js": () => import("./../../../src/pages/conditions-we-treat/knee-pain.js" /* webpackChunkName: "component---src-pages-conditions-we-treat-knee-pain-js" */),
  "component---src-pages-conditions-we-treat-neck-and-head-pain-js": () => import("./../../../src/pages/conditions-we-treat/neck-and-head-pain.js" /* webpackChunkName: "component---src-pages-conditions-we-treat-neck-and-head-pain-js" */),
  "component---src-pages-conditions-we-treat-pain-in-childhood-and-adolescence-js": () => import("./../../../src/pages/conditions-we-treat/pain-in-childhood-and-adolescence.js" /* webpackChunkName: "component---src-pages-conditions-we-treat-pain-in-childhood-and-adolescence-js" */),
  "component---src-pages-conditions-we-treat-postural-strain-at-work-and-driving-js": () => import("./../../../src/pages/conditions-we-treat/postural-strain-at-work-and-driving.js" /* webpackChunkName: "component---src-pages-conditions-we-treat-postural-strain-at-work-and-driving-js" */),
  "component---src-pages-conditions-we-treat-pregnancy-related-discomfort-js": () => import("./../../../src/pages/conditions-we-treat/pregnancy-related-discomfort.js" /* webpackChunkName: "component---src-pages-conditions-we-treat-pregnancy-related-discomfort-js" */),
  "component---src-pages-conditions-we-treat-shoulder-pain-js": () => import("./../../../src/pages/conditions-we-treat/shoulder-pain.js" /* webpackChunkName: "component---src-pages-conditions-we-treat-shoulder-pain-js" */),
  "component---src-pages-conditions-we-treat-sports-injury-js": () => import("./../../../src/pages/conditions-we-treat/sports-injury.js" /* webpackChunkName: "component---src-pages-conditions-we-treat-sports-injury-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-cranial-osteopathy-js": () => import("./../../../src/pages/services/cranial-osteopathy.js" /* webpackChunkName: "component---src-pages-services-cranial-osteopathy-js" */),
  "component---src-pages-services-infant-feeding-coaching-js": () => import("./../../../src/pages/services/infant-feeding-coaching.js" /* webpackChunkName: "component---src-pages-services-infant-feeding-coaching-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-osteopathy-js": () => import("./../../../src/pages/services/osteopathy.js" /* webpackChunkName: "component---src-pages-services-osteopathy-js" */),
  "component---src-pages-services-sports-remedial-massage-js": () => import("./../../../src/pages/services/sports-remedial-massage.js" /* webpackChunkName: "component---src-pages-services-sports-remedial-massage-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-pages-visual-language-js": () => import("./../../../src/pages/visual-language.js" /* webpackChunkName: "component---src-pages-visual-language-js" */)
}

